import React, { useState } from "react";
import "../Container.css";
import DanhSachPhatHanh from "./DanhSachPhatHanh";
import "./SapPhatHanh.css";
const SapPhatHanh = () => {
  const [type, setType] = useState(1);
  const setTypeActive = (e, type) => {
    const allWithClass = Array.from(document.getElementsByClassName("item"));
    allWithClass.forEach((element) => {
      element.classList.remove("active_item");
    });
    e.target.classList.add("active_item");
    setType(type);
  };

  return (
    <div className="comingsoon_content_section">
      <div className="comingsoon">
        <div className="comingsoon_title">SÁCH SẮP PHÁT HÀNH</div>
        <div className="comingsoon_content_ul">
          <ul className="comingsoon_content_links">
            <li
              className="item"
              onClick={(e) => {
                setTypeActive(e, 1);
              }}
            >
              Tâm lý học
            </li>
            <li
              className="item"
              onClick={(e) => {
                setTypeActive(e, 2);
              }}
            >
              Hiện sinh
            </li>

            <li
              className="item"
              onClick={(e) => {
                setTypeActive(e, 3);
              }}
            >
              Tác giả Việt Nam
            </li>
            <li
              className="item"
              onClick={(e) => {
                setTypeActive(e, 4);
              }}
            >
              Kỹ năng hiện đại
            </li>
            <li
              className="item"
              onClick={(e) => {
                setTypeActive(e, 5);
              }}
            >
              Khác
            </li>
          </ul>
        </div>
        <div className="comingsoon_content">
          <DanhSachPhatHanh type={type}></DanhSachPhatHanh>
        </div>
      </div>
    </div>
  );
};

export default SapPhatHanh;
