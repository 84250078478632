import React from "react";
import Navbar from "../components/Navbar/Navbar";
import dataJson from "../constants/data";
import dataPartner from "../constants/doitac";
import DoiTac from "../containers/doitac/DoiTac";
import GioiThieuChung from "../containers/gioithieuchung/GioiThieuChung";
import SapPhatHanh from "../containers/sapphathanh/SapPhatHanh";
import Slidebot from "../containers/slidebot/SlideBot";
import Order from "./datmua/Order";

const Home = () => {
  return (
    <>
      <Navbar navbarLinks={dataJson.navbarLinks} />
      <GioiThieuChung></GioiThieuChung>
      <DoiTac data={dataPartner.doitac}></DoiTac>
      <SapPhatHanh></SapPhatHanh>
      <Slidebot></Slidebot>
      {/* <img src={near} width="100%"></img> */}
      <Order id="order" apiGGSheet={dataJson.apiGGSheet}></Order>
      <div className="footer_section">© 2022 tuetri All Rights Reserved</div>
    </>
  );
};

export default Home;
