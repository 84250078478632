import React from "react";
import introduce_img from "../../assets/img_gioithieuchung.png";
import "../Container.css";
import "./GioiThieuChung.css";
const GioiThieuChung = () => {
  return (
    <div className="introduce_content_section">
      <div className="container">
        <div className="introduce">
          <div className="introduce_title">GIỚI THIỆU CHUNG</div>
          <div className="introduce_content">
            <div className="introduce_logo_first">
              <img src={introduce_img} width="50%"></img>
            </div>
            <div className="introduce_left">
              {/* <div className="introduce_left_title">
                2 điểm nổi bật nhất của sách
              </div> */}
              <div className="introduce_left_text">
                Tuệ Tri được thành lập vào tháng 11 năm 2021 với sự chung sức
                của các cá nhân có kinh nghiệm, nhiệt huyết và đam mê với ngành
                sách. Mục tiêu hàng đầu của chúng tôi là mang đến cho bạn đọc
                những cuốn sách có đề tài hay, nội dung chất lượng, với tiêu
                chuẩn in ấn và mỹ thuật tốt nhất.
              </div>
              {/* <div className="introduce_left_text">
                Trong cuốn sách này, Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Lorem ipsum dolor sit amet. Consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua.
              </div> */}
            </div>
            <div className="introduce_right">
              <img src={introduce_img} width="70%"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GioiThieuChung;
