import React from "react";
import ImageGallery from "react-image-gallery";
import data from "../../constants/slidebot";
import "../Container.css";
import "./SlideBot.css";
const Content = () => {
  console.log(data);
  const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];
  return (
    <ImageGallery
      items={data.data}
      originalWidth="100%"
      showThumbnails={false}
      showNav={false}
      showPlayButton={false}
      showFullscreenButton={false}
      showBullets={true}
    />
  );
};

export default Content;
