import React from "react";
import { animateScroll as scroll } from "react-scroll";
import bg_top from "../../assets/bg_top.jpg";
import "./Navbar.css";

const Navbar = ({ navbarLinks }) => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <>
      <img
        src={bg_top}
        width="100%"
        height="100%"
        style={{ position: "relative", verticalAlign: "middle" }}
        alt=""
      ></img>

      {/* <div className="banner_top"></div> */}
    </>
  );
};

export default Navbar;
