import React from "react";
import "./Footer.css";
import "../../containers/Container.css";
import footer_img_thongbao from "../../assets/footer_img_thongbao.png";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const Footer = ({ id }) => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div className="app_footer_section">
      <div className="container footer_containner" id={id}>
        <div className="footer_top" style={{ height: "147px" }}>
          <div className="footer_top_title">TRÍ TUỆ ĐỨC PHẬT</div>
          <div className="footer_top_title1">Cách đạt an bình thực sự</div>
          <img
            alt=""
            src={footer_img_thongbao}
            className="footer_img_thongbao"
          ></img>
        </div>
        <div className="footer_bottom">
          <div className="footer_left">
            <h3 className="footer_bottom_title">Liên lạc với chúng tôi</h3>
            <div className="footer_item_info">
              <i className="fa">&#xf095;</i>
              &nbsp;&nbsp;0981 251 451
            </div>
            <div className="footer_item_info">
              <i className="fa">&#xf0e0;</i>
              &nbsp;&nbsp;hang@tuetri.vn
            </div>
            <div className="footer_item_info">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
              &nbsp;&nbsp;130 Nguyễn Công Trứ, Phường Nguyễn Thái Bình, Quận 1,
              TP. HCM
            </div>
          </div>
          <div className="footer_center">
            <h3 className="footer_bottom_title">Chính sách</h3>
            <div className="footer_item_info" onClick={scrollToTop}>
              <Link to="/chinh-sach-thanh-toan" className="footer_subpage">
                Chính sách thanh toán
              </Link>
            </div>
            <div className="footer_item_info" onClick={scrollToTop}>
              <Link to="/chinh-sach-van-chuyen" className="footer_subpage">
                Chính sách vận chuyển
              </Link>
            </div>
            <div className="footer_item_info" onClick={scrollToTop}>
              <Link to="/chinh-sach-bao-mat" className="footer_subpage">
                Chính sách bảo mật
              </Link>
            </div>
            <div className="footer_item_info" onClick={scrollToTop}>
              Chính sách đổi trả hoàn tiền
            </div>
          </div>
          <div className="footer_right">
            <h3 className="footer_bottom_title">Điều khoản</h3>
            <div className="footer_item_info" onClick={scrollToTop}>
              <Link to="/dieu-khoan-su-dung" className="footer_subpage">
                Điều khoản sử dụng
              </Link>
            </div>
            <div className="footer_item_info" onClick={scrollToTop}>
              <Link to="/dieu-khoan-su-dung" className="footer_subpage">
                Điều khoản giao dịch
              </Link>
            </div>
            <div className="footer_item_info" onClick={scrollToTop}>
              <Link to="/dieu-khoan-su-dung" className="footer_subpage">
                Dịch vụ tiện ích
              </Link>
            </div>
            <div className="footer_item_info" onClick={scrollToTop}>
              <Link to="/dieu-khoan-su-dung" className="footer_subpage">
                Quyền sở hữu trí tuệ
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
