import React, { useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import "../Container.css";
import "./DoiTac.css";
const Content = ({ id, data }) => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  let slidesToShow = 3;
  let showArrows = true;
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  console.log(windowWidth);
  if (windowWidth <= 580) {
    slidesToShow = 2;
    showArrows = false;
  } else {
    slidesToShow = 5;
    showArrows = false;
  }

  const breakPoints = [
    { width: 320, itemsToShow: 2 },
    { width: 375, itemsToShow: 2 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4, itemsToScroll: 4 },
    { width: 992, itemsToShow: 5, itemsToScroll: 5 },
    { width: 1200, itemsToShow: 5, itemsToScroll: 5 },
    { width: 1400, itemsToShow: 5 },
    { width: 1600, itemsToShow: 5 },
  ];
  return (
    <div className="partner_content_section">
      <div className="container">
        <div className="partner">
          <div className="partner_title">NHỮNG ĐỐI TÁC UY TÍN</div>
          <div className="partner_content">
            <Carousel
              breakPoints={breakPoints}
              showArrows={showArrows}
              enableAutoPlay={true}
            >
              {data.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="partner_item">
                      <img src={item.image} width="100%"></img>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
