import Carousel from "react-elastic-carousel";
import React, { useEffect, useState } from "react";
import dataComingSoon from "../../constants/sapphathanh";
import "./SapPhatHanh.css";

const DanhSachPhatHanh = (type) => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [data, setdata] = useState([]);
  const [reset, setreset] = useState(0);
  const [itemToShow, setitemToShow] = useState(3);
  const [showArrows, setshowArrows] = useState(true);

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    let dataFilter = dataComingSoon.sach.filter(
      (item) => item.type === type.type
    );
    setdata(dataFilter);
    setreset(0);
  }, [type]);
  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    if (windowWidth <= 580) {
      setitemToShow(2);
      setshowArrows(false);
    } else {
      setitemToShow(3);
      setshowArrows(true);
    }
  }, [windowWidth]);

  return (
    <Carousel
      itemsToShow={itemToShow}
      showArrows={showArrows}
      sliderPosition={reset}
      enableAutoPlay={true}
      itemPadding={[5, 10]}
      showEmptySlots={true}
    >
      {data?.map((item, index) => {
        return <img key={index} src={item.image} width="100%"></img>;
      })}
    </Carousel>
  );
};

export default DanhSachPhatHanh;
