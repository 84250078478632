const data_flipbook = {
  content:
    '<iframe style="width:550px;height:350px" src="http://online.anyflip.com/krujr/cwdm/"  seamless="seamless" scrolling="no" frameborder="0" allowtransparency="true" allowfullscreen="true" ></iframe>',
};

const navbarLinks = [
  { url: "author", title: "Tác giả" },
  { url: "order", title: "Ưu đãi" },
  { url: "review", title: "Review" },
  { url: "order", title: "Đặt mua" },
  // { url: "#introduce", title: "Giới thiệu" },
  // { url: "#content", title: "Nội dung" },
  // { url: "#read", title: "Đọc thử" },
  // { url: "#author", title: "Tác giả" },
  // { url: "#rate", title: "Đánh giá" },
  // { url: "#question", title: "Hỏi đáp" },
  // { url: "#buy", title: "Đặt mua" },
];

const apiGGSheet =
  "https://sheet.best/api/sheets/d7bbc8a7-e315-42d5-bd1e-542c1f06fdc5";

export default { data_flipbook, navbarLinks, apiGGSheet };
