import React from "react";
import "./Container.css";
import "./Subpage.css";
const Subpage = ({ subpagecode }) => {
  console.log(subpagecode);
  return (
    <div className="subpage_contain">
      {subpagecode === "chinhsachthanhtoan" ? (
        <div className="subpage">
          <h2>Chính sách thanh toán</h2>
          Giao hàng và thanh toán bằng tiền mặt khi nhận hàng:
          <p>
            Sau khi khách hàng đặt hàng thành công trên website sẽ có email của
            Tuệ Tri gửi về email quý khách để thông báo lại thông tin đơn hàng
            quý khách vừa đặt. Ngày hôm nay nhân viên xử lý đơn hàng sẽ gọi điện
            để xác nhận lại một lần nữa đơn hàng trước khi chuyển sách cho đơn
            vị vận chuyển.
          </p>
          <p>
            Các yêu cầu giao hàng cần có thông tin chính xác về người nhận, địa
            chỉ, số điện thoại. Một số trường hợp nhạy cảm như: giá trị đơn hàng
            lớn, thời gian giao hàng buổi tối, địa chỉ giao hàng trong ngõ, khu
            vực xa trung tâm.. Nhân viên xử lý đơn hàng sẽ kiểm tra và trao đổi
            thêm với khách hàng, thống nhất cách thức giao hàng cụ thể trước khi
            giao.
          </p>
        </div>
      ) : (
        ""
      )}
      {subpagecode === "chinhsachvanchuyen" ? (
        <div className="subpage">
          <h2>Chính sách vận chuyển</h2>
          Chính sách vận chuyển Đối với khách hàng nhận sách ở nội thành Hồ Chí
          Minh phí vận chuyển là 20.000đ. Đối với khách hàng nhận sách ở những
          khu vực khác phí vận chuyển là 40.000đ. Trong trường hợp đặc biệt có
          thay đổi về chi phí vận chuyển, nhân viên xử lý đơn hàng sẽ gọi điện
          trao đổi và thống nhất với khách hàng trước khi giao.
        </div>
      ) : (
        ""
      )}
      {subpagecode === "chinhsachbaomat" ? (
        <div className="subpage">
          <h2>Chính sách bảo mật</h2>
          <h4>1. Mục đích và phạm vi thu thập thông tin:</h4>
          <p>
            Để truy cập và sử dụng một số dịch vụ tại website, bạn có thể sẽ
            được yêu cầu đăng ký với chúng tôi thông tin cá nhân (Email, Họ tên,
            Số ĐT liên lạc…). Mọi thông tin khai báo phải đảm bảo tính chính xác
            và hợp pháp. Chúng tôi không chịu mọi trách nhiệm liên quan đến pháp
            luật của thông tin khai báo.
          </p>
          <p>
            Chúng tôi thu thập và sử dụng thông tin cá nhân bạn với mục đích phù
            hợp và hoàn toàn tuân thủ nội dung của "Chính sách bảo mật" này. Khi
            cần thiết, chúng tôi có thể sử dụng những thông tin này để liên hệ
            trực tiếp với bạn dưới các hình thức như: Gởi thư ngỏ, đơn đặt hàng,
            thư cảm ơn, thông tin về kỹ thuật và bảo mật...
          </p>
          <p>
            Trong một số trường hợp, chúng tôi có thể thuê một đơn vị độc lập để
            tiến hành các dự án nghiên cứu thị trường và khi đó thông tin của
            bạn sẽ được cung cấp cho đơn vị này để tiến hành dự án. Bên thứ ba
            này sẽ bị ràng buộc bởi một thỏa thuận về bảo mật mà theo đó họ chỉ
            được phép sử dụng những thông tin được cung cấp cho mục đích hoàn
            thành dự án.
          </p>
          <h4>2. Phạm vi sử dụng thông tin:</h4>
          <p>
            - Website thu thập và sử dụng thông tin cá nhân quý khách với mục
            đích phù hợp và hoàn toàn tuân thủ nội dung của “Chính sách bảo mật”
            này.
          </p>
          <p>
            - Khi cần thiết, chúng tôi có thể sử dụng những thông tin này để
            liên hệ trực tiếp với bạn dưới các hình thức như: gởi thư ngỏ, đơn
            đặt hàng, thư cảm ơn, thông tin về kỹ thuật và bảo mật, quý khách có
            thể nhận được thư định kỳ cung cấp thông tin sản phẩm, dịch vụ mới,
            thông tin về các sự kiện sắp tới hoặc thông tin tuyển dụng nếu quý
            khách đăng kí nhận email thông báo.
          </p>
          <h4>3. Thời gian lưu trữ thông tin: </h4>
          <p>
            Ngoại trừ các trường hợp về sử dụng thông tin cá nhân như đã nêu
            trong chính sách này, chúng tôi cam kết sẽ không tiết lộ thông tin
            cá nhân bạn ra ngoài. Thông tin sẽ được lưu trữ vĩnh viễn cho đến kh
            quý khách không sử dụng dịch vụ của chúng tôi nữa.
          </p>
          <h4>4. Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân:</h4>
          <p>CÔNG TY CỔ PHẦN VĂN HOÁ & CÔNG NGHỆ TUỆ TRI </p>
          <p>
            Địa chỉ : 130 Nguyễn Công Trứ, Phường Nguyễn Thái Bình, Quận 1, TP.
            HCM
          </p>
          <h4>
            5. Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ
            liệu cá nhân của mình:
          </h4>
          <p>
            Bất cứ thời điểm nào bạn cũng có thể truy cập và chỉnh sửa những
            thông tin cá nhân của mình theo các links thích hợp mà chúng tôi
            cung cấp.
          </p>
          <h4>6. Cam kết bảo mật thông tin cá nhân khách hàng: </h4>
          <p>
            Chúng tôi cam kết bảo mật thông tin cá nhân của bạn bằng mọi cách
            thức có thể.
          </p>
          <p>
            Chúng tôi sẽ sử dụng nhiều công nghệ bảo mật thông tin khác nhau
            nhằm bảo vệ thông tin này không bị truy lục, sử dụng hoặc tiết lộ
            ngoài ý muốn. Chúng tôi khuyến cáo bạn nên bảo mật các thông tin
            liên quan đến mật khẩu truy xuất của bạn và không nên chia sẻ với
            bất kỳ người nào khác. Nếu sử dụng máy tính chung nhiều người, bạn
            nên đăng xuất, hoặc thoát hết tất cả cửa sổ Website đang mở.
          </p>
        </div>
      ) : (
        ""
      )}
      {subpagecode === "dieukhoannguoidung" ? (
        <div className="subpage">
          <p>
            Khi quý khách truy cập vào trang web của chúng tôi có nghĩa là quý
            khách đồng ý với các điều khoản này.
          </p>
          <p>
            Trang web có quyền thay đổi, chỉnh sửa, thêm hoặc lược bỏ bất kỳ
            phần nào trong Quy định và Điều kiện sử dụng, vào bất cứ lúc nào.
            Các thay đổi có hiệu lực ngay khi được đăng trên trang web mà không
            cần thông báo trước. Và khi quý khách tiếp tục sử dụng trang web,
            sau khi các thay đổi về quy định và điều kiện được đăng tải, có
            nghĩa là quý khách chấp nhận với những thay đổi đó.
          </p>
          <p>
            Quý khách vui lòng kiểm tra thường xuyên để cập nhật những thay đổi
            của chúng tôi.
          </p>
          <h4>1. Hướng dẫn sử dụng web</h4>
          <p>
            - Khi vào web của chúng tôi, người dùng tối thiểu phải 18 tuổi hoặc
            truy cập dưới sự giám sát của cha mẹ hay người giám hộ hợp pháp.
          </p>
          <p>
            Chúng tôi cấp giấy phép sử dụng để bạn có thể mua sắm trên web trong
            khuôn khổ điều khoản và điều kiện sử dụng đã đề ra.
          </p>
          <p>
            - Nghiêm cấm sử dụng bất kỳ phần nào của trang web này với mục đích
            thương mại hoặc nhân danh bất kỳ đối tác thứ ba nào nếu không được
            chúng tôi cho phép bằng văn bản. Nếu vi phạm bất cứ điều nào trong
            đây, chúng tôi sẽ hủy giấy phép của bạn mà không cần báo trước.
          </p>
          <p>
            - Trang web này chỉ dùng để cung cấp thông tin sản phẩm chứ chúng
            tôi không phải nhà sản xuất nên những nhận xét hiển thị trên web là
            ý kiến cá nhân của khách hàng, không phải của chúng tôi.
          </p>
          <p>
            - Quý khách phải đăng ký tài khoản với thông tin xác thực về bản
            thân và phải cập nhật nếu có bất kỳ thay đổi nào. Mỗi người truy cập
            phải có trách nhiệm với mật khẩu, tài khoản và hoạt động của mình
            trên web. Hơn nữa, quý khách phải thông báo cho chúng tôi biết khi
            tài khoản bị truy cập trái phép. Chúng tôi không chịu bất kỳ trách
            nhiệm nào, dù trực tiếp hay gián tiếp, đối với những thiệt hại hoặc
            mất mát gây ra do quý khách không tuân thủ quy định.
          </p>
          <p>
            - Trong suốt quá trình đăng ký, quý khách đồng ý nhận email quảng
            cáo từ website. Sau đó, nếu không muốn tiếp tục nhận mail, quý khách
            có thể từ chối bằng cách nhấp vào đường link ở dưới cùng trong mọi
            email quảng cáo.
          </p>
          <h4>2. Chấp nhận đơn hàng và giá cả</h4>
          <p>
            - Chúng tôi có quyền từ chối hoặc hủy đơn hàng của quý khách vì bất
            kỳ lý do gì vào bất kỳ lúc nào. Chúng tôi có thể hỏi thêm về số điện
            thoại và địa chỉ trước khi nhận đơn hàng.
          </p>
          <p>
            - Chúng tôi cam kết sẽ cung cấp thông tin giá cả chính xác nhất cho
            người tiêu dùng. Tuy nhiên, đôi lúc vẫn có sai sót xảy ra, ví dụ như
            trường hợp giá sản phẩm không hiển thị chính xác trên trang web hoặc
            sai giá, tùy theo từng trường hợp chúng tôi sẽ liên hệ hướng dẫn
            hoặc thông báo hủy đơn hàng đó cho quý khách. Chúng tôi cũng có
            quyền từ chối hoặc hủy bỏ bất kỳ đơn hàng nào dù đơn hàng đó đã hay
            chưa được xác nhận hoặc đã bị thanh toán.
          </p>
          <h4>3. Thương hiệu và bản quyền</h4>
          <p>
            - Mọi quyền sở hữu trí tuệ (đã đăng ký hoặc chưa đăng ký), nội dung
            thông tin và tất cả các thiết kế, văn bản, đồ họa, phần mềm, hình
            ảnh, video, âm nhạc, âm thanh, biên dịch phần mềm, mã nguồn và phần
            mềm cơ bản đều là tài sản của chúng tôi. Toàn bộ nội dung của trang
            web được bảo vệ bởi luật bản quyền của Việt Nam và các công ước quốc
            tế. Bản quyền đã được bảo lưu.
          </p>
          <h4>4. Quyền pháp lý</h4>
          <p>
            - Các điều kiện, điều khoản và nội dung của trang web này được điều
            chỉnh bởi luật pháp Việt Nam và Tòa án có thẩm quyền tại Việt Nam sẽ
            giải quyết bất kỳ tranh chấp nào phát sinh từ việc sử dụng trái phép
            trang web này.
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Subpage;
