import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./containers/Home";
import ChinhSachBaoMat from "./pages/ChinhSachBaoMat";
import ChinhSachThanhToan from "./pages/ChinhSachThanhToan";
import ChinhSachVanChuyen from "./pages/ChinhSachVanChuyen";
import DieuKhoanSuDung from "./pages/DieuKhoanSuDung";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<Home />} />
          <Route
            path="/chinh-sach-thanh-toan"
            element={<ChinhSachThanhToan />}
          />
          <Route path="/chinh-sach-bao-mat" element={<ChinhSachBaoMat />} />
          <Route
            path="/chinh-sach-van-chuyen"
            element={<ChinhSachVanChuyen />}
          />
          <Route path="/dieu-khoan-su-dung" element={<DieuKhoanSuDung />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
