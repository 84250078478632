import img_doitac from "../assets/images/doitac/Qv@3x.png";
import img_doitac1 from "../assets/images/doitac/Qv@3x-1.png";
import img_doitac2 from "../assets/images/doitac/Qv@3x-2.png";
import img_doitac3 from "../assets/images/doitac/Qv@3x-3.png";
import img_doitac4 from "../assets/images/doitac/Qv@3x-4.png";
import img_doitac5 from "../assets/images/doitac/Qv@3x-5.png";
import img_doitac6 from "../assets/images/doitac/Qv@3x-6.png";
import img_doitac7 from "../assets/images/doitac/Qv@3x-7.png";
import img_doitac8 from "../assets/images/doitac/Qv@3x-8.png";
import img_doitac9 from "../assets/images/doitac/Qv@3x-9.png";
import img_doitac10 from "../assets/images/doitac/Qv@3x-10.png";
import img_doitac11 from "../assets/images/doitac/Qv@3x-11.png";
import img_doitac12 from "../assets/images/doitac/Qv@3x-12.png";
import img_doitac13 from "../assets/images/doitac/Qv@3x-13.png";
import img_doitac14 from "../assets/images/doitac/Qv@3x-14.png";
import img_doitac15 from "../assets/images/doitac/Qv@3x-15.png";

const doitac = [
  { image: img_doitac },
  { image: img_doitac1 },
  { image: img_doitac2 },
  { image: img_doitac3 },
  { image: img_doitac4 },
  { image: img_doitac5 },
  { image: img_doitac6 },
  { image: img_doitac7 },
  { image: img_doitac8 },
  { image: img_doitac9 },
  { image: img_doitac10 },
  { image: img_doitac11 },
  { image: img_doitac12 },
  { image: img_doitac13 },
  { image: img_doitac14 },
  { image: img_doitac15 },
];

export default { doitac };
