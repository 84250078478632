import React from "react";
import Footer from "../components/Footer/Footer";
import NavbarSubpage from "../components/Navbar/NavbarSubpage";
import dataJson from "../constants/data";
import Subpage from "../containers/Subpage";
const ChinhSachBaoMat = () => {
  return (
    <>
      <NavbarSubpage navbarLinks={dataJson.navbarLinks} />
      <Subpage subpagecode={"chinhsachbaomat"}></Subpage>
      <Footer></Footer>
    </>
  );
};

export default ChinhSachBaoMat;
