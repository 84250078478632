import { Grid } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "../../components/Notification/notifications.css";
import customData from "../../constants/local.json";
import "../Container.css";
import "./Order.css";

const Order = ({ id, apiGGSheet }) => {
  let tinhJSONFisrt = customData.filter((item) => parseInt(item.id) === 1);

  const refhoten = React.useRef();
  const refphone1 = React.useRef();
  const refphone2 = React.useRef();
  const refsoluongmua = React.useRef();
  const refdiachichitiet = React.useRef();
  const refghichu = React.useRef();

  const [dataHuyen, setDataHuyen] = useState(tinhJSONFisrt[0].districts);
  const [dataXa, setDataXa] = useState(tinhJSONFisrt[0].districts[0].wards);

  const [tenXa, setTenXa] = useState(String);
  const [tenHuyen, setTenHuyen] = useState(String);
  const [tenTinh, setTenTinh] = useState(String);

  const [gridWidth, setgridWidth] = useState(9);
  const [windowWidth, setWindowWidth] = useState(0);

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    if (windowWidth <= 580) {
      setgridWidth(12);
    } else {
      setgridWidth(9);
    }
  }, [windowWidth]);

  const onChangeTinh = (e) => {
    let tinhJSON = customData.filter(
      (item) => parseInt(item.id) == e.target.value
    );
    console.log(tinhJSON);
    setDataHuyen(tinhJSON[0].districts);
    setDataXa([]);
    setTenTinh(tinhJSON[0].name);
  };

  const onChangeHuyen = (e) => {
    let huyenJSON = dataHuyen.filter(
      (item) => parseInt(item.id) == e.target.value
    );
    setDataXa(huyenJSON[0].wards);
    setTenHuyen(huyenJSON[0].name);
  };

  const onChangeXa = (e) => {
    let xaJSON = dataXa.filter((item) => parseInt(item.id) == e.target.value);
    setTenXa(xaJSON[0].name);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const hoten = refhoten.current.value;
    const phone1 = refphone1.current.value;
    const phone2 = refphone2.current.value;
    const soluongmua = refsoluongmua.current.value;
    const diachichitiet = refdiachichitiet.current.value;
    const ghichu = refghichu.current.value;

    const diachinhan =
      diachichitiet +
      (tenXa.length > 0 ? ", " + tenXa : "") +
      (tenHuyen.length > 0 ? ", " + tenHuyen : "") +
      (tenTinh.length > 0 ? ", " + tenTinh : "");

    const data = {
      "Họ và tên": hoten,
      "Số điện thoại 1": phone1,
      "Số điện thoại 2": phone2,
      "Số lượng mua": soluongmua,
      "Địa chỉ nhận hàng": diachinhan,
      "Ghi chú": ghichu,
    };

    console.log(data);
    axios
      .post(apiGGSheet, data)
      .then((response) => {
        NotificationManager.success(
          "Đơn hàng của bạn đã đăng ký thành công!",
          "Thông báo!"
        );
      })
      .catch(function (error) {
        NotificationManager.error(
          "Đơn hàng của bạn đăng ký không thành công! Vui lòng liên hệ số hotline để được hỗ trợ. Xin cảm ơn!",
          "Thông báo!"
        );
      });
  };
  return (
    <div className="order_section">
      <div className="container">
        <div className="order_content" id={id}>
          <div className="order_top_title">
            <div className="order_top_title1">LIÊN HỆ VỚI CHÚNG TÔI</div>
            <p className="order_text" style={{ color: "#ACACAC" }}>
              Xin liên hệ{" "}
              <strong style={{ color: "white" }}>info@tuetri.vn</strong> hoặc
              gọi <strong style={{ color: "white" }}>0981251451</strong>
            </p>
            <p className="order_text" style={{ color: "#ACACAC" }}>
              hoặc để lại thông tin bên dưới, chúng tôi sẽ liên hệ lại trong
              vòng 2 tiếng đồng hồ
            </p>
          </div>
          <div className="order_form_content">
            <form className="order_form" onSubmit={handleSubmit}>
              <Grid container spacing={7}>
                <Grid
                  className="grid grid-label"
                  item
                  xs={3}
                  style={{
                    lineHeight: "48px",
                    color: "#ACACAC",
                    fontSize: "16px",
                  }}
                >
                  Số điện thoại *
                </Grid>
                <Grid className="grid" item xs={gridWidth}>
                  <input
                    className="input"
                    name="sdt2"
                    placeholder="Nhập số điện thoại"
                    ref={refphone2}
                  ></input>
                </Grid>
                <Grid
                  className="grid grid-label"
                  item
                  xs={3}
                  style={{
                    lineHeight: "48px",
                    color: "#ACACAC",
                    fontSize: "16px",
                  }}
                >
                  Vấn đề cần liên hệ *
                </Grid>
                <Grid className="grid" item xs={gridWidth}>
                  <input
                    className="input"
                    name="sdt2"
                    placeholder="Nhập nội dung"
                    ref={refphone2}
                  ></input>
                </Grid>

                <Grid className="grid grid_center" item xs={12}>
                  <button className="order_btn_buy" type="submit">
                    GỬI YÊU CẦU
                  </button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default Order;
