import img_tamlyhoc from "../assets/images/sapphathanh/tamlyhoc/book.png";
import img_tamlyhoc1 from "../assets/images/sapphathanh/tamlyhoc/book-1.png";
import img_tamlyhoc2 from "../assets/images/sapphathanh/tamlyhoc/book-2.png";

import img_hiensinh from "../assets/images/sapphathanh/hiensinh/book.png";
import img_hiensinh1 from "../assets/images/sapphathanh/hiensinh/book-1.png";
import img_hiensinh2 from "../assets/images/sapphathanh/hiensinh/book-2.png";
import img_hiensinh3 from "../assets/images/sapphathanh/hiensinh/book-3.png";
import img_hiensinh4 from "../assets/images/sapphathanh/hiensinh/book-4.png";

import img_khac from "../assets/images/sapphathanh/khac/book.png";

import img_kynanghiendai from "../assets/images/sapphathanh/kynanghiendai/book.png";
import img_kynanghiendai1 from "../assets/images/sapphathanh/kynanghiendai/book-1.png";
import img_kynanghiendai2 from "../assets/images/sapphathanh/kynanghiendai/book-2.png";
import img_kynanghiendai3 from "../assets/images/sapphathanh/kynanghiendai/book-3.png";
import img_kynanghiendai4 from "../assets/images/sapphathanh/kynanghiendai/book-4.png";
import img_kynanghiendai5 from "../assets/images/sapphathanh/kynanghiendai/book-5.png";
import img_kynanghiendai6 from "../assets/images/sapphathanh/kynanghiendai/book-6.png";

import img_tacgiavietnam from "../assets/images/sapphathanh/tacgiavietnam/book.png";

const sach = [
  { type: 1, image: img_tamlyhoc },
  { type: 1, image: img_tamlyhoc1 },
  { type: 1, image: img_tamlyhoc2 },
  { type: 2, image: img_hiensinh },
  { type: 2, image: img_hiensinh1 },
  { type: 2, image: img_hiensinh2 },
  { type: 2, image: img_hiensinh3 },
  { type: 2, image: img_hiensinh4 },
  { type: 3, image: img_tacgiavietnam },
  { type: 4, image: img_kynanghiendai },
  { type: 4, image: img_kynanghiendai1 },
  { type: 4, image: img_kynanghiendai2 },
  { type: 4, image: img_kynanghiendai3 },
  { type: 4, image: img_kynanghiendai4 },
  { type: 4, image: img_kynanghiendai5 },
  { type: 4, image: img_kynanghiendai6 },
  { type: 5, image: img_khac },
];

export default { sach };
