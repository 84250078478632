import img_slidebot1 from "../assets/img_near_bot_1.jpg";
import img_slidebot2 from "../assets/img_near_bot_2.jpg";
import img_slidebot3 from "../assets/img_near_bot_3.jpg";

const data = [
  { original: img_slidebot1 },
  { original: img_slidebot2 },
  { original: img_slidebot3 },
];

export default { data };
