import React from "react";
import { TiThMenu } from "react-icons/ti";
import { IoMdCloseCircle } from "react-icons/io";
import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom";
import "./NavbarSubpage.css";

const Navbar = ({ navbarLinks }) => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <nav className="navsub">
      <img
        alt=""
        width="100%"
        height="auto"
        style={{ position: "relative" }}
      ></img>
      <div className="navsub_content" onClick={scrollToTop}>
        <div className="bannersubpage">
          <div className="navsub_title">
            <Link to="/" className="navsub_title_link">
              TRÍ TUỆ ĐỨC PHẬT
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
